//Localisation
export const SET_LOCALE = "SET_LOCALE"

//Set Active Pillar
export const SET_ACTIVE_PILLAR = "SET_ACTIVE_PILLAR"

export const UPDATE_SLIDE = "UPDATE_SLIDE"

export const ACTIVATE_SLIDER = "ACTIVATE_SLIDER"

//Query String
export const SET_QUERY_STRING = "SET_QUERY_STRING"

export const TOGGLE_VIDEO_MODAL = "TOGGLE_VIDEO_MODAL"
