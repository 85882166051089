import {
  SET_LOCALE,
  SET_ACTIVE_PILLAR,
  UPDATE_SLIDE,
  ACTIVATE_SLIDER,
  SET_QUERY_STRING,
  TOGGLE_VIDEO_MODAL,
} from "../actions/type"

export function localeReducer(state = { locale: "tc" }, action) {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, locale: action.locale }
    default:
      return state
  }
}

export function activePillarReducer(
  state = { activePillar: "adventure", isOpened: false, currentSlide: 0 },
  action
) {
  switch (action.type) {
    case SET_ACTIVE_PILLAR:
      return { ...state, activePillar: action.pillar }
    case UPDATE_SLIDE:
      return { ...state, currentSlide: action.num }
    case ACTIVATE_SLIDER:
      return { ...state, isOpened: action.bool }
    default:
      return state
  }
}

export function queryStringReducer(state = { queryString: null }, action) {
  switch (action.type) {
    case SET_QUERY_STRING:
      return { ...state, queryString: action.query }
    default:
      return state
  }
}

export function videoModalReducer(state = { opened: false }, action) {
  switch (action.type) {
    case TOGGLE_VIDEO_MODAL:
      return { ...state, opened: action.opened }
    default:
      return state
  }
}